import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Marker} from '../model/marker';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private mapUrl = 'api/markers';
  constructor(
    private http: HttpClient) {
  }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getMarkers(): Observable<Marker[]> {
    return this.http.get<Marker[]>(this.mapUrl);
  }
}
