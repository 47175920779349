import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthGuard} from '../model/authguard';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthGuard, private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.inMaintenance() === true) {
      this.router.navigate(['/maintenance']);
      return false;
    }
    return true;
  }

}
