import {Component, Input, OnInit} from '@angular/core';
import {TapListService} from '../shared/services/taplist.service';
import {Taplist} from '../shared/model/taplist';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faBeer } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-tap-list',
  templateUrl: './modal-tap-list.component.html',
  styleUrls: ['./modal-tap-list.component.css']
})
export class ModalTapListComponent implements OnInit {
  tapLists: Taplist[];
  taplist: Taplist;
  faBeer = faBeer;
  @Input() id: number;


  constructor(private tapListService: TapListService, private modalService: NgbActiveModal) { }

  ngOnInit(): void {
    this.getTapLists();
  }

  getTapList(id: number) {
    // @ts-ignore
    for (let i = 0 ; i < this.tapLists[0].length; i++) {
      if (this.tapLists[0][i].id == id) {
        this.taplist = this.tapLists[0][i];
        break;
      }
    }
  }

  getTapLists() {
    this.tapListService.getTaplists().subscribe(res => {
      this.tapLists = Object.keys(res).map(e => res[e]);
      this.getTapList(this.id);
    });
  }

  close(reason?: any): void {
    this.modalService.close(reason);
  }

  confirm(reason?: any): void {
    this.modalService.close(reason);
  }

  goToContact() {
    window.location.href = '#contact';
    this.modalService.close();
  }
}
