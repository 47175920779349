import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapComponent} from './map/map.component';
import {ModalTapListComponent} from './modal-tap-list/modal-tap-list.component';
import {UnderMaintenanceComponent} from './under-maintenance/under-maintenance.component';
import {AuthGuardService} from './shared/services/authguard.service';
import {NotFoundComponent} from './not-found/not-found.component';


const routes: Routes = [
  {path: '', component: MapComponent, canActivate: [AuthGuardService]},
  {path: 'maintenance', component: UnderMaintenanceComponent},
  {path: '**', component: NotFoundComponent },
  {path: ':id', component: ModalTapListComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
