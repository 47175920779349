import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
mainentance = false;

  constructor() { }

  inMaintenance() {
    return this.mainentance;
  }

  setInMaintenance(m) {
    this.mainentance = m;
  }
}
