import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html'
})
export class ModalComponent implements OnInit {
  @Input() inline: boolean;
  @Input() title: string;
  @Input() body: string;
  @Input() closeBtn: string;
  @Input() confirmBtn: string;
  @Input() subContent: string[];

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
    if (this.inline !== true) {
      this.inline = false;
    }
  }


  close(reason?: any): void {
    this.modalService.close(reason);
  }

  confirm(reason?: any): void {
    this.modalService.close(reason);
  }
}
