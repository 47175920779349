import { Component } from '@angular/core';
import {Marker} from './shared/model/marker';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bts';
  marker: Marker[];

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('it');
  }
}
