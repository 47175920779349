import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-switch-translation',
  templateUrl: './switch-translation.component.html',
  styleUrls: ['./switch-translation.component.css']
})
export class SwitchTranslationComponent implements OnInit {
  constructor(private translate: TranslateService) {
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
  }

}
