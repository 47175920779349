import { Component, OnInit } from '@angular/core';
import {AuthGuard} from '../shared/model/authguard';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {
  form: FormGroup;

  constructor(private authService: AuthGuard, private router: Router, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (!this.authService.inMaintenance()) {
      this.router.navigate(['/']);
    }

    this.form = this.fb.group({
      hiddenValue: new FormControl('')
    });

  }

  onChange() {
    this.authService.setInMaintenance(this.form.get('hiddenValue').value);
    if (this.form.get('hiddenValue').value === 'false') {
      this.router.navigate(['/']);
    }
  }
}
