import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/Observable';

export function callback() {
  ModalService.modal = null;
}

export class ModalArgs {
  title?: string = 'modal.errortitle';
  body?: string | string[] = 'modal.genericError';
  closeBtn?: string = null;
  okBtn?: string = null;
  inline?: boolean = false;
  messages?: string[] = [];
  params?: string[] = [];
  success?: (res: any) => any = callback;
  error?: (res: any) => any = callback;
  image?: string = null;
}

@Injectable()
export class ModalService {
  public static modal: NgbModalRef;
  public openmodals = [];

  constructor(
    private ngbModalService: NgbModal
  ) {
  }

  public create(content: any, options: NgbModalOptions = null, args: ModalArgs = null) {

    const openModal = (): Promise<any> => {
      const ref = this.ngbModalService.open(content, options || {});
      if (args) {
        ref.componentInstance.title = args.title;
        if (typeof args.body === 'string') {
          ref.componentInstance.body = args.body;
        } else if (ref.componentInstance.body) {
          ref.componentInstance.body = args.body[0];
          if (args.body.length > 1) {
            ref.componentInstance.subContent = args.body.slice(1);
          }
        }
        if (args.closeBtn != null) {
          ref.componentInstance.closeBtn = args.closeBtn;
        }
        if (args.okBtn != null) {
          ref.componentInstance.confirmBtn = args.okBtn;
        }
        ref.componentInstance.inline = args.inline;
      }
      const noop = () => {
      };
      return ref.result.then(args.success || noop, args.error || noop);
    }


    return openModal();


  }

  public open(content: any, options: NgbModalOptions = null, title: string = 'modal.errortitle',
              body: string | string[] = 'modal.genericError', closeBtn: string = null, okBtn: string = null,
              success: (res: any) => any = callback, error: (res: any) => any = callback, inline: boolean = false, image: string = null
  ): Promise<any> {
    if (ModalService.modal == null) {
      ModalService.modal = this.ngbModalService.open(content, options);
      ModalService.modal.componentInstance.title = title;
      if (typeof body === 'string') {
        ModalService.modal.componentInstance.body = body;
      } else {
        ModalService.modal.componentInstance.body = body[0];
        if (body.length > 1) {
          ModalService.modal.componentInstance.subContent = body.slice(1);
        }
      }
      if (closeBtn != null) {
        ModalService.modal.componentInstance.closeBtn = closeBtn;
      }
      if (okBtn != null) {
        ModalService.modal.componentInstance.confirmBtn = okBtn;
      }
      if (image != null) {
        ModalService.modal.componentInstance.image = image;
      }
      ModalService.modal.componentInstance.inline = inline;
      return ModalService.modal.result.then(success, error);
    }
  }

  public close(reason?: string) {
    ModalService.modal.close(reason);
    ModalService.modal = null;
  }

}
