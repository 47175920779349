import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {ModalComponent} from '../modal/modal.component';
import {ModalService} from '../modal/modal.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(protected modalService: ModalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(req.method);
    if (req.method === 'DELETE') {
      return from(this.modalService.open(ModalComponent, {backdrop: 'static'}, '',
        'Do you confirm?', 'NO', 'YES',
        (result) => {
          if (result === 'OK') {
            return next.handle(req).toPromise();
          } else {

          }
        })
      );
    } else {
      return next.handle(req);
      }
  }
}
