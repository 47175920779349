import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Taplist} from '../model/taplist';

@Injectable({
  providedIn: 'root'
})
export class TapListService {
  private tapListUrl = 'api/taplists';
  constructor(
    private http: HttpClient) {
  }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getTaplists(): Observable<Taplist[]> {
    return this.http.get<Taplist[]>(this.tapListUrl);
  }
  getTaplist(id: number): Observable<Taplist> {
    const url = `${this.tapListUrl}/${id}`;
    console.log(url);
    return this.http.get<Taplist>(url);
  }

}
